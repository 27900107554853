import Link from "next/link";
import React from "react";
import SubArrow from "./svgs/sub-arrow.svg";

const SubArrowLink = ({ href, text, className }: { href: string; text: string; className?: string }) => {
	return (
		<Link href={href} className={`flex items-center gap-2 pl-[3.875rem] mt-1.5 ${className ? className : ""}`}>
			<SubArrow className="w-3 mb-1 lg:w-4 stroke-[2px]" />
			<span className="link-underline">{text}</span>
		</Link>
	);
};

export default SubArrowLink;
