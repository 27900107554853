import ArrowLink from "@/components/ArrowLink";
import FeaturedSection from "@/components/FeaturedSection";
import SubrandSection from "@/components/SubrandSection";
import PageContainer from "@/containers/PageContainer";
import { addApolloState, initializeApollo } from "@neonjungle/headless";
import { HomePageDocument, HomePageQuery, NewsPage, ProjectPage, Subrand } from "graphql/generated-types";
import type { GetStaticProps } from "next";
import HeroImage from "@/components/HeroImage";
import PageHeading from "@/components/PageHeading";
import { PageProps, PreviewDataType } from "@/lib/types";
import { Helmet, renderMeta } from "@/components/Helmet";
import { Curve } from "@/components/Curve";
import { useMeasure } from "react-use";


export default function Home({ data }: { data: HomePageQuery }) {
	const { home } = data;
	const [ref, { height }] = useMeasure<HTMLElement>();

	return (
		<PageContainer mainRef={ref} mainElementClasses="lg:grid" script={data.analyticsSettings?.script!}>
			<Helmet>
				{renderMeta(
					data.home.searchImage?.rendition?.url,
					data.home.seoTitle || data.home.title,
					` | ${data.sites[0].siteName}`,
					data.home.searchDescription as string,
					data.sites[0].siteName,
					data.sites[0].hostname
				)}
			</Helmet>
			<div className="container grid-cols-3 lg:grid">
				<PageHeading title={home.tagline!} subtitle={home.explainer}>
					<ArrowLink className="mt-4 lg:mt-auto lg:mb-1" href={home.ctaLink?.url!} text={home.ctaText!} />
				</PageHeading>
			</div>
			{home?.heroImage?.rendition?.url && (
				<HeroImage
					isHomePage={true}
					src={home?.heroImage?.rendition?.url!}
					alt={home?.heroImage?.rendition?.image?.title!}
				/>
			)}
			<p className="container pt-10 text-lg lg:pt-16 lg:text-2xl">{home.introText!}</p>
			<ArrowLink className="container mt-6 lg:hidden" href="/about" text="Read more about us" />
			<div className="relative">
				<FeaturedSection
					title={home.projectListing?.title!}
					indexSlug={home.projectListing?.url!}
					indexText={"All Projects"}
					featuredList={home.featuredProjects?.map((featured) => featured?.project) as ProjectPage[]}
				/>
			</div>
			{home.subrands?.map((subrand, i) => (
				<SubrandSection key={i} subrand={subrand as Subrand} />
			))}
			<div className="relative">
				<FeaturedSection
					title={home.newsListing?.title!}
					indexSlug={home.newsListing?.url!}
					indexText={"All News"}
					featuredList={home.featuredNews?.map((featured) => featured?.news) as NewsPage[]}
				/>
			</div>
			<Curves mainHeight={height} />
		</PageContainer>
	);
}

const Curves = ({ mainHeight }: { mainHeight: number }) => {
	return (
		<>
			<Curve className="absolute left-0 top-[6.5rem] lg:top-52 -z-10" from={90} to={0} />
			{mainHeight > 2000 && <Curve className="absolute right-0 -z-20 top-[82rem]" from={-90} to={-180} />}
			{mainHeight > 3800 && <Curve className="absolute right-0 top-[60%] -z-20" from={-90} to={-180} />}
			{mainHeight > 1500 && <Curve className="absolute bottom-[75rem] left-0 -z-20" from={90} to={0} />}
		</>
	);
};

export const getStaticProps: GetStaticProps = async (context) => {
	const apolloClient = initializeApollo();
	const { previewData } = context as { previewData: PreviewDataType };

	const { data } = await apolloClient.query({
		query: HomePageDocument,
		variables: {
			token: previewData?.previewToken,
		},
	});

	const pageProps = addApolloState(apolloClient, {
		props: {
			data,
		},
	}) as PageProps;

	pageProps.revalidate = 10;

	return pageProps;
};
