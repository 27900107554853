import { removeRootPage } from "@/lib/utils";
import { NewsPage, ProjectPage } from "graphql/generated-types";
import React from "react";
import ArrowLink from "./ArrowLink";
import ListingCard from "./ListingCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useMedia } from "react-use";

const FeaturedSection = ({
	title,
	indexSlug,
	indexText,
	featuredList,
}: {
	title: string;
	indexSlug: string;
	indexText: string;
	featuredList: ProjectPage[] | NewsPage[];
}) => {
	const isDesktop = useMedia("(min-width: 1023px)", false);

	return (
		<section className="pt-11 pb-[3.75rem] lg:container lg:py-56">
			<div className="flex justify-between pb-5 lg:pb-[3.875rem] to-lg:container">
				<h2 className="text-xl lg:text-2xl">{title}</h2>
				{isDesktop && <ArrowLink className="mt-5" href={indexSlug} text={indexText} />}
			</div>
			<div className="to-lg:pl-5 lg:grid grid-cols-3 gap-[3.75rem]">
				{isDesktop ? (
					featuredList.map((obj, i) => (
						<ListingCard
							key={i}
							imageSrc={obj?.heroImage?.rendition?.url || ""}
							imageAlt={obj?.heroImage?.rendition?.image.title || ""}
							title={obj?.title}
							linkHref={removeRootPage(obj?.urlPath)}
							linkText={obj?.__typename === "NewsPage" ? "View article" : "View project"}
							tags={
								obj?.__typename === "NewsPage"
									? obj.tags?.map((tag) => ({ name: tag?.name!, slug: indexSlug + "?tag=" + tag?.slug! }))
									: undefined
							}
						/>
					))
				) : (
					<SwiperComponent featuredList={featuredList} indexSlug={indexSlug} />
				)}
			</div>
		</section>
	);
};

const SwiperComponent = ({
	featuredList,
	indexSlug,
}: {
	featuredList: ProjectPage[] | NewsPage[];
	indexSlug: string;
}) => (
	<Swiper slidesPerView={1.1} className="w-full overflow-visible">
		{featuredList.map((obj, i) => (
			<SwiperSlide key={i} style={{ height: "auto" }}>
				<ListingCard
					imageSrc={obj?.heroImage?.rendition?.url || ""}
					imageAlt={obj?.heroImage?.rendition?.image.title || ""}
					title={obj?.title}
					linkHref={removeRootPage(obj?.urlPath)}
					linkText={obj?.__typename === "NewsPage" ? "View article" : "View project"}
					tags={
						obj?.__typename === "NewsPage"
							? obj.tags?.map((tag) => ({ name: tag?.name!, slug: indexSlug + "?tag=" + tag?.slug! }))
							: undefined
					}
				/>
			</SwiperSlide>
		))}
	</Swiper>
);

export default FeaturedSection;
