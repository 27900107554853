import { Subrand } from "graphql/generated-types";
import Image from "next/image";
import React from "react";
import ArrowLink from "./ArrowLink";
import { removeRootPage } from "@/lib/utils";
import SubArrowLink from "./SubArrowLink";

const SubrandSection = ({ subrand }: { subrand: Subrand }) => {
	return (
		<section className="pt-11 pb-[3.75rem] lg:py-[1.875rem] border-t-3 border-navy odd:border-b-3">
			<div className="container grid to-lg:gap-5 lg:grid-cols-2 gap-x-5">
				<h3 className="order-1 text-xl lg:text-3xl lg:self-end">{subrand.name}</h3>
				<div className="order-3">
					<p className="max-w-[35rem] mt-1 text-lg leading-tight lg:text-xl">{subrand.description}</p>
					<ArrowLink
						className="mt-6 mb-2 lg:mb-4 lg:mt-12"
						href={removeRootPage(subrand.link?.urlPath!)}
						text={`Learn more about ${subrand.name}`}
					/>
					{subrand.products?.map((product, i) => (
						<SubArrowLink key={i} href={removeRootPage(product?.page?.urlPath!)} text={product?.name!} />
					))}
				</div>
				<Image
					className="order-2 justify-self-end lg:row-span-2"
					src={subrand.image?.rendition?.url!}
					alt={subrand.image?.rendition?.image?.title!}
					width={1050}
					height={810}
				/>
			</div>
		</section>
	);
};

export default SubrandSection;
